@import url("https://fonts.googleapis.com/css2?family=Barlow&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --appPrimaryFont: "Kanit", sans-serif;
  --appColor: #22242b;
  --appDarkColor: #1b1c22;
  --appLightColor: #262830;
  --appVeryLightColor: #31333e;
  --appVeryVeryLightColor: #3b3e4a;
  --appGreyColor: gray;
  --appBlueColor: #4e91e7;
  --appYellowColor: #efc258;
  --appGreenColor: #5a9f37;
  --appRedColor: #ed6767;
  --appGlobalRadius: 10px;
  --appGlobalPadding: 15px;
  --textColor: #fff;
  --buttonRadius: 5px;
  --appButtonColor: var(--appBlueColor);
  --appButtonSecondary: transparent;
  --appButtonDanger: var(--appRedColor);
  --appButtonConfirmColor: var(--appBlueColor);
  --navSize: 200px;
  --mapMenuSize: 380px;
  --iconButtonHeight: 37px;
}

hr {
  border: none;
  border-bottom: 1px dashed var(--appDarkColor);
  height: 0px;
  width: 100%;
}

[data-theme="dark"] {
}

* {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  background-color: var(--appColor);
  padding: 0;
  margin: 0;
  color: var(--textColor);
  overflow-x: hidden;
}
@media all and (max-width: 640px) {
  :root {
    --navSize: 100%;
    --appGlobalPadding: 30px;
    --mapMenuSize: 100%;
  }
}
