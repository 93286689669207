.AppTag {
  font-size: 0.6em;
  padding: 5px;
  border-radius: 3px;
  display: inline-block;
  border: 1px solid transparent;
}

.AppTag.Primary {
  border-color: var(--appBlueColor);
  color: var(--appBlueColor);
}

.AppTag.Warning {
  border-color: var(--appYellowColor);
  color: var(--appYellowColor);
}

.AppTag.Success {
  border-color: var(--appGreenColor);
  color: var(--appGreenColor);
}

.AppTag.Default {
  border-color: var(--appGreyColor);
  color: var(--appGreyColor);
}

.AppTag.Error {
  border-color: var(--appRedColor);
  color: var(--appRedColor);
}
