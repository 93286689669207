.NavContent {
  position: fixed;
  background-color: var(--appLightColor);
  top: 0;
  left: 0;
  bottom: 0;
  width: var(--navSize);
  overflow: hidden;
  z-index: 999999;
  transition: transform ease-in-out 0.3s;
}
.NavButtons {
  display: none;
}

.NavHeader,
.NavBottom {
  text-align: center;
}

.NavHeader {
  font-family: var(--appPrimaryFont);
}

.NavHeader svg {
  font-size: 2em;
  margin: 20px 0 10px 0;
}

.NavHeader h2 {
  font-weight: 300;
}

.Nav ul {
  font-size: 1em;
  margin: 20px 0;
}

.Nav ul > li {
  font-size: 0.8em;
}

.Nav ul > li.Nomap {
  color: var(--appGreyColor);
  text-align: center;
}

.Nav ul > li:not(.Nomap) {
  padding: 10px;
  background-color: var(--appVeryLightColor);
  margin: 5px;
}

.Nav ul > li:not(.Nomap):hover {
  cursor: pointer;
  background-color: var(--appColor);
}

.NavButtons {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: var(--appGlobalPadding);
  z-index: 999999999;
  text-align: center;
}

@media all and (max-width: 640px) {
  .NavClosed > .NavContent {
    transform: translateX(-100vw);
  }
  .NavOpened > .NavContent {
    transform: translateX(0);
  }
  .NavButtons {
    display: block;
  }
}
