.Section {
  position: relative;
  padding: var(--appGlobalPadding);
}

.Section.NoPadding {
  padding: 0 !important;
}

.TitleSection {
  font-size: 1em;
}

@media (max-width: 640px) {
  .Section {
    padding: calc(var(--appGlobalPadding) / 2);
  }
}
