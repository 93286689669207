.modalOverlay {
  z-index: 9999999999;
}

.modalOverlay:not(.fullsize) {
  display: inline-block;
}

.ModalForm {
  display: flex;
  flex-direction: column;
}

.ModalForm > li > label {
  font-family: var(--appPrimaryFont);
}

.ModalForm > li > input {
  display: block;
  width: 100%;
  margin: auto;
  border: none;
  outline: none;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 10px;
  color: white;
}

.ModalForm > li > input:focus {
  background-color: rgba(255, 255, 255, 0.2);
}

.ModalForm > li > p {
  font-size: 0.7em;
  font-style: italic;
}

.modalOverlay.fullsize {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(1, 1, 1, 0.3);
  justify-content: center;
  align-items: center;
}

.modalOverlay.fullsize > .modal {
  min-width: 450px;
  max-height: 90%;
}

.modal {
  overflow: auto;
  background-color: var(--appVeryVeryLightColor);
  border-radius: 5px;
  -webkit-box-shadow: 0px 10px 13px -7px #8f8f8f,
    5px 5px 15px 5px rgba(0, 0, 0, 0);
  box-shadow: 0px 10px 13px -7px #8f8f8f, 5px 5px 15px 5px rgba(0, 0, 0, 0);
  overflow: auto;
  animation: comeOn 0.3s forwards linear;
}

.modalTitle {
  background-color: var(--appColor);
  color: white;
  padding: 15px;
}

.modalContent {
  padding: 30px 15px;
}

.modalFooter {
  border-top: 1px dashed var(--appVeryLightColor);
  padding: 15px;
}

@keyframes comeOn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 640px) {
  .modalOverlay.fullsize > .modal {
    min-width: inherit;
    width: 100%;
    margin: 0 5px;
  }
}
