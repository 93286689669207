.notification {
  position: fixed;
  bottom: 0;
  right: 0;
  color: var(--textColor);
  z-index: 99999999;
  width: 300px;
  background-color: var(--appLightColor);
  animation: slideIn forwards 0.3s ease-in-out;
  margin: 10px;
  border-left: 2px solid var(--appBlueColor);
  padding: 20px;
  border-top-right-radius: var(--appGlobalRadius);
  border-bottom-right-radius: var(--appGlobalRadius);
}

.notificationHeader {
  font-weight: bold;
  margin-bottom: 5px;
  font-family: var(--appPrimaryFont);
  font-weight: 400;
}

.notificationBody {
  font-size: 0.9em;
  color: var(--appGreyColor);
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translate(0);
  }
}

@media (max-width: 640px) {
  .notification {
    width: 100%;
    font-size: 0.8em;
  }
}
