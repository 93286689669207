.AppButtons {
  --button-radius: 3px;
  outline: none;
  padding: 5px 8px;
  border: none;
  border: 1px solid transparent;
  background-color: transparent;
  font-size: 0.8em;
  border-radius: 0;
  font-family: "PT Sans", sans-serif;
  transition: transform 0.3s;
  position: relative;
  border-radius: var(--button-radius);
  z-index: 1;
  overflow: hidden;
  color: transparent !important;
  font-family: var(--appPrimaryFont);
  font-weight: 400;
}

.AppButtons.Flash::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  opacity: 0.3;
  background: rgb(255, 255, 255);
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: moveX 2s infinite;
}

.AppButtons:hover {
  cursor: pointer;
}

.AppButtons:not(.Flash)::before,
.AppButtons:not(.Flash)::after {
  color: white;
  content: attr(data-content);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  border-radius: var(--buttonRadius);
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s, opacity 0.3s;
}

.AppButtons::before {
  transform: translateY(0%);
}

.AppButtons::after {
  background-color: rgba(1, 1, 1, 0.1);
  transform: translateY(100%);
}

.AppButtons:hover::after {
  transform: translateY(0);
}

.AppButtons:hover::before {
  transform: translateY(-100%);
}

.AppButtons.Default {
  background-color: var(--appButtonColor);
}

.AppButtons.Default.Confirm {
  background-color: var(--appButtonConfirmColor);
}

.AppButtons.Dashed {
  border-color: var(--appButtonColor);
}

.AppButtons.Primary {
  background-color: var(--appButtonColor);
}

.AppButtons.Secondary {
  background-color: var(--appButtonSecondary);
}

.AppButtons.Danger {
  background-color: var(--appButtonDanger);
}

.AppButtons.Dashed::before,
.AppButtons.Dashed::after {
  color: var(--appButtonColor);
}

.AppButtons.Dashed.Confirm {
  border-color: var(--appButtonConfirmColor);
  color: var(--appButtonConfirmColor);
}

@keyframes moveX {
  0% {
    transform: rotate(-45deg) translateX(-100%);
  }

  35% {
    transform: rotate(-45deg) translateX(-100%);
  }

  65% {
    transform: rotate(-45deg) translateX(100%);
  }

  100% {
    transform: rotate(-45deg) translateX(100%);
  }
}

@media (max-width: 640px) {
  .AppButtons {
    font-size: 0.7em;
    padding: 5px 10px;
  }
}
