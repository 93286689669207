.SpotInformations {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--appDarkColor);
  z-index: 999;
  padding: var(--appGlobalPadding);
}

.SpotInformationsTitle > h3 {
  font-family: var(--appPrimaryFont);
  font-weight: normal;
  margin-left: 7px;
  width: calc(100% - 45px);
}

.SpotInformationsTitle > h3 > svg {
  float: right;
  color: var(--appGreenColor);
  padding-top: 3px;
}

.SpotInformationsContent {
  margin-bottom: 7px;
}

.SpotInformationsContent > label:not(:last-child) {
  margin-right: 5px;
}

.LatLon > label {
  color: var(--appGreyColor);
  font-size: 0.9em;
}

.SpotInformationsActions > button:not(:last-child) {
  margin-right: 5px;
}

.MapActions {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
  text-align: right;
  padding: 15px;
}

.MapMenu {
  background-color: var(--appVeryLightColor);
  position: absolute;
  top: 0;
  width: var(--mapMenuSize);
  right: 0;
  bottom: 0;
  transform: translateX(100vw);
  transition: transform 0.3s;
  z-index: 9999;
}

.MapMenuCloseButton {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
}

.MapMenuCloseButton > button {
  width: 100%;
  border-radius: 0;
}

.MapMenu.opened {
  transform: translateX(0);
}

.MapActions > button {
  background-color: var(--appVeryLightColor) !important;
}

.MapActions > button:not(:last-child) {
  margin-right: 5px;
}

.AppSpotList {
  max-height: calc(100vh - 100px);
  overflow: auto;
}

.AppSpotList > li {
  font-size: 0.8em;
  padding: 10px 15px;
  cursor: pointer;
  border-left: 1px solid var(--appLightColor);
}

.MobileSeparator {
  display: none;
}

.AppSpotList > li:not(:last-child) {
  margin-bottom: 3px;
}

.AppSpotList > li:hover {
  color: var(--appBlueColor);
  border-left-color: var(--appBlueColor);
}

.SpotInformationsTitle > h3,
.SpotInformationsTitle > button {
  display: inline-block;
}

.SpotInformationsTitle > button {
  background-color: transparent;
}

.AppSpotList > li:not(:last-child) {
  border-bottom: 1px dashed var(--appVeryLightColor);
}

.MapMenu em {
  font-size: 0.8em;
  color: var(--appGreyColor);
}

.RateList {
  display: inline-block;
  width: calc(var(--iconButtonHeight) * 3);
}

.RateList > li {
  display: inline-block;
}

@media all and (max-width: 640px) {
  .LatLon {
    display: none;
  }
  .MobileSeparator {
    display: block;
  }
  .SpotInformationsTitle > button {
    vertical-align: top;
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
  .SpotInformationsTitle > h3 {
    font-size: 1em;
    display: block;
    margin-left: 0;
    margin-top: 10px;
  }
  .SpotInformationsTitle {
    margin-bottom: 10px;
  }

  .SpotInformations,
  .MapMenu,
  .MapMenuCloseButton {
    padding-bottom: calc(
      (var(--appGlobalPadding) * 2) + var(--iconButtonHeight)
    );
  }

  .MapMenuCloseButton > button {
    background-color: transparent !important;
  }
}
