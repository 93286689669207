button.IconButton {
  margin: auto;
  position: relative;
  outline: none;
  border: none;
  border-radius: 50%;
  color: white;
  cursor: pointer;
}

button.IconButton > span {
  position: absolute;
  top: calc(10px - var(--iconButtonHeight) / 1.5);
  left: calc(0px - 50%);
  width: 200%;
  text-align: center;
  display: none;
  color: white;
  font-size: 0.8em;
  animation: comeOnTop ease-in-out forwards 0.3s;
  transform: translateY(10px);
  opacity: 0;
}

button.IconButton > span > em {
  background-color: rgba(1, 1, 1, 0.2);
  border-radius: 2px;
  padding: 5px 8px;
  font-style: normal;
}

button.IconButton:hover > span {
  display: block;
}

button.IconButton.Primary {
  background-color: var(--appBlueColor);
}

button.IconButton.Default {
  background-color: transparent;
}

button.IconButton.Small {
  width: calc(var(--iconButtonHeight) / 1.5);
  height: calc(var(--iconButtonHeight) / 1.5);
  font-size: 0.6em;
}

button.IconButton.Normal {
  width: var(--iconButtonHeight);
  height: var(--iconButtonHeight);
}

button.IconButton.Default.Highlighted.HighlightPrimary {
  color: var(--appBlueColor);
}

button.IconButton.Default.Highlighted.HighlightSuccess {
  color: var(--appGreenColor);
}

button.IconButton.Default.Highlighted.HighlightDefault {
  color: var(--appGreyColor);
}

button.IconButton.Default.Highlighted.HighlightWarning {
  color: var(--appYellowColor);
}

button.IconButton.Default.Highlighted.HighlightError {
  color: var(--appRedColor);
}

@keyframes comeOnTop {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
