.AppTabs {
  display: flex;
  justify-content: space-around;
}

.AppTabs > li {
  border-bottom: 1px solid var(--appVeryVeryLightColor);
  font-size: 0.8em;
  padding: 5px;
  padding-bottom: 10px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  font-family: var(--appPrimaryFont);
  font-weight: 400;
}

.AppTabs > li:hover,
.AppTabs > li.selected {
  border-color: var(--appBlueColor);
}

.AppTabs > li:not(:last-child) {
  margin-right: 5px;
}
